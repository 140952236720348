<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Solicitação de documentos</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Solicitação de documentos',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col>
                                    <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select dense :items="dataset.eventStatus" label="Status"
                                        v-model="dataList.filter.status" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col>
                                    <v-select dense :items="dataset.eventTypes" label="Tipo" v-model="dataList.filter.type"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Hab/Div</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum usuário foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.user.name`]="{ item }">
                                <span class="list-item-title text-capitalize">{{ item.user.name }}</span><br />
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="getColorByStatus(item.status)" label text-color="white">
                                    {{ item.status | enum(dataset.eventStatus, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.amount`]="{ item }">
                                R$ {{ item.amount | currency }}
                            </template>

                            <template v-slot:[`item.enable_contradictory`]="{ item }">
                                    <span v-if="item.enable_contradictory == 2">
                                        {{ item.contradictory_deadline_at | defaultDate('DD/MM/YYYY') }}
                                    </span>
                                    <span v-else>Fechado</span>
                            </template>


                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.analyzed_at`]="{ item }">
                                <span v-if="item.analyzed_at && item.analyzed_at !== '0000-00-00 00:00:00'"> {{
                                    item.contract |
                                    defaultDate("DD/MM/YYYY HH:mm:ss") }}</span>
                                <span v-else>N/A</span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import eventStatus from "@/common/data/event-status.data";

Vue.use(VueLodash)

export default {
    name: "UserList",

    data: () => ({
        dataset: {
            eventStatus: [{ id: "", name: "Todos" }, ...eventStatus],
            eventTypes: [{ id: "", name: "Todos" }, { id: 1, name: "Habilitação" }, { id: 2, name: "Divergência" }],
            data: {}
        },
        dataList: {
            filter: {
                name: "",
                status: "",
                type: ""
            },
            headers: [
                { text: "Credor", value: "userCreditor.name" },
                { text: "Valor", value: "amount" },
                { text: "Solicitado em", value: "created_at" },
                { text: "Prazo contraditório", value: "enable_contradictory" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {
        clientPageText() {
            return `${this.dataList.paging.page} de ${this.dataList.paging.pages}`;
        },

        pageChanged(page) {
            this.dataList.paging.page = page;
            this.list();
        },

        pageSizeChanged(size) {
            this.dataList.paging.size = size;
            this.list(true);
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var start = (this.dataList.options.page - 1) * limit;

            try {
                const result = await axios.get('/api/admin/official-letters/client/list', { params: { start: start, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.count;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`solicitacao-documentos/${item.id}`);
        },
    },
};
</script>