<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn v-if="dataset.data.status == 0 && dataset.data.enable_contradictory == 2" type="button"
                            elevation="0" color="primary" @click="updateContradictory"
                            :loading="dataset.loading">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/solicitacao-documentos">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="dataset.data.status != 0 || dataset.data.enable_contradictory != 2">
            <v-col>
                <v-banner single-line outlined>
                    <v-icon slot="icon" color="warning" size="36">
                        mdi-alert-outline
                    </v-icon>


                    Prazo para enviar contraditórios está encerrado!

                </v-banner>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm" v-if="dataset.data && dataset.data.userCreditor">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes credor</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense disabled id="name" label="Nome completo"
                                                v-model="dataset.data.userCreditor.name" required :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense disabled label="E-mail"
                                                v-model="dataset.data.userCreditor.email" required outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-currency-field label="Valor" dense disabled
                                                v-model="dataset.data.amount" prefix="R$" :allowNegative=false hide-details="auto"
                                                outlined required :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card-form-outlined" color="gray">
                            <v-card-title class="card-group-title">
                                <div class="title-container">
                                    <div class="title-block">
                                        <span id="title" class="table-card-title">Contraditório</span>
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <tiptap-vuetify
                                            :disabled="dataset.data.status !== 0 || dataset.data.enable_contradictory != 2"
                                            v-model="dataset.data.contradictory_reason" :rules="[$rules.required]"
                                            :extensions="extensions" hide-details="auto" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card-form-outlined" color="gray">
                            <v-card-title class="card-group-title">
                                <div class="title-container">
                                    <div class="title-block">
                                        <span id="title" class="table-card-title">Análise</span>
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <div v-html="dataset.data.reason"></div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Negócios</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <span>Não foi apresentado pedido de habilitação ou divergência de crédito.</span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogViewDoc" max-width="60%" @keydown.esc="cancel">
            <v-card>
                <v-img :src="selectedDoc" alt="" contain />
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import states from "@/common/data/states.data"
import string from "@/common/util/string"

import userTypes from "@/common/data/user-types.data"
import currencyTypes from "@/common/data/currency-types.data"

import axios from "axios"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Table, TableCell, TableHeader,
    TableRow, TodoList, TodoItem
} from 'tiptap-vuetify'


export default {
    name: "UserDetails",

    components: { 'tiptap-vuetify': TiptapVuetify },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            userTypes: userTypes,
            currencyTypes: currencyTypes,
            breadcrumbItems: [],
            states: states,
            data: {
                contradictory_reason: "",
                items: []
            },
            events: []
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
        selectedDoc: null,
        dialogViewDoc: false,
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            [Table, {
                options: {
                    resizable: true
                }
            }],
            TableCell,
            TableHeader,
            TableRow,
        ],
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/divergencias");
        },

        openDoc(doc) {
            this.selectedDoc = doc;
            this.dialogViewDoc = true;
        },

        truncateText(text, maxLength) {
            if (!text) return text;

            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        async loadData() {
            await this.load();

            this.setPage();
            this.listBreadCrumbItems();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `Detalhes da solicitação`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Divergência",
                disabled: false,
                to: "/divergencias",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: `Detalhes da solicitação`,
                disabled: true,
                exact: true,
            });
        },

        async updateContradictory() {

            if (!this.dataset.data.contradictory_reason) {
                this.$dialog.notify.error("Contraditório é obrigatório", { position: "top-right", timeout: 2000 });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja atualizar a divergência?`,
                title: `Atualizar divergência`,
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            const formData = new FormData();

            formData.append("contradictory_reason", this.dataset.data.contradictory_reason)

            if (this.dataset.data.contradictory_reason_file) {
                formData.append('reason', this.dataset.data.contradictory_reason_file);
            }

            try {
                const response = await axios.patch(`/api/admin/user-events/contradictory/${this.$route.params.id}`, formData);

                this.$dialog.notify.info(`Divergência atualizada com sucesso!`, { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async load() {

            try {
                const result = await axios.get(`/api/admin/official-letters/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                console.log('data ', data);

                this.dataset.data = data;

                this.dataset.data.smallReason = data.small_reason || ""
                this.dataset.data.reason = data.reason || ""

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

    },
};
</script>
  
<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>