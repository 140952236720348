<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Credores</div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field dense label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field dense label="E-mail" v-model="dataList.filter.email" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field dense label="CPF/CNPJ" v-model="dataList.filter.registration" hide-details
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-btn v-on="on" id="btnFilter" type="submit" color="primary" dark
                                                elevation="2">Filtrar
                                            </v-btn>
                                        </template>
                                        <span>Filtrar dados</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Credores</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum credor foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar :size="30">
                                    <img :src="'https://ui-avatars.com/api/?name=' + item.name" />
                                </v-avatar>
                                <span class="ml-4 list-item-title">{{ item.name }}</span><br />
                            </template>

                            <template v-slot:[`item.registration`]="{ item }">
                                {{ formatCpfCnpj(item.registration) }}
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator'

import object from "@/common/util/object"

export default {
    data: () => ({
        user: null,
        dataList: {
            filter: {
                name: "",
                client: "",
                email: "",
                registration: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "E-mail", value: "email" },
                { text: "CPF/CNPJ", value: "registration" },
                { text: "Criado em", value: "created_at" },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    mounted: function () {
        this.user = this.$root.user;
    },

    created() {
        this.load();
    },

    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;

            try {
                const result = await axios.get('/api/admin/users-creditors/client', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

    },
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.dashboard-card-title {
    color: var(--v-primary-base);
    font-size: 24px;
    font-weight: 700;
}

.dashboard-card-metric {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: 500;
}
</style>
