var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"font-weight-bold mt-10 title-page"},[_vm._v("Credores")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"filter-card"},[_c('v-card-title',{staticClass:"filter-card-title"},[_vm._v("Filtro")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.load()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nome","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.name),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "name", $$v)},expression:"dataList.filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"E-mail","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.email),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "email", $$v)},expression:"dataList.filter.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"CPF/CNPJ","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.registration),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "registration", $$v)},expression:"dataList.filter.registration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"btnFilter","type":"submit","color":"primary","dark":"","elevation":"2"}},on),[_vm._v("Filtrar ")])]}}])},[_c('span',[_vm._v("Filtrar dados")])])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',{staticClass:"table-card-title"},[_vm._v("Credores")]),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhum credor foi encontrado","loading-text":"Carregando dados...","options":_vm.dataList.options,"footer-props":_vm.dataList.footerOptions},on:{"update:items-per-page":_vm.pageSizeChanged,"update:page":_vm.pageChanged,"update:options":function($event){return _vm.$set(_vm.dataList, "options", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":30}},[_c('img',{attrs:{"src":'https://ui-avatars.com/api/?name=' + item.name}})]),_c('span',{staticClass:"ml-4 list-item-title"},[_vm._v(_vm._s(item.name))]),_c('br')]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCpfCnpj(item.registration))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }