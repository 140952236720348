<template>
  <v-container :class="$platform.isMobile() ? 'px-10' : ''" fill-height grid-list-md text-xs-center>
    <v-row justify="center" style="margin: 0px">
      <v-col cols="12" sm="8" md="6" lg="5" justify="space-between">
        <v-row style="padding: 100px 0px 10px 0px">
          <div class="mb-10">
            <span class="title-login">Acesso devedora</span>
            <br>
            <span class="darkGray--text">Informe seu e-mail e senha para acessar</span>
          </div>
        </v-row>
        <v-row justify="center" style="height: 300px">
          <v-form ref="form" v-model="loginForm.validForm" lazy-validation style="width: 100%" @submit.prevent="login">
            <v-text-field placeholder="Digite seu e-mail" label="E-mail" ref="username" outlined
              :rules="[$rules.required, $rules.email]" v-model="loginForm.user.username"></v-text-field>

            <v-text-field outlined label="Senha" :append-icon="loginForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              required :rules="[$rules.required]" :type="loginForm.showPassword ? 'text' : 'password'"
              v-model="loginForm.user.password" @click:append="loginForm.showPassword = !loginForm.showPassword"
              hide-details="auto" class="mb-4" id="password"></v-text-field>

            <v-row justify="center">
              <v-col>
                <v-btn v-if="$platform.isMobile()" width="100%" color="primary" elevation="2" x-large
                  style="height: 56px; margin-top: 10px" :loading="loginForm.loading" type="submit">Acessar</v-btn>
                <v-btn v-else large color="primary" elevation="2" style="margin-top: 10px"
                  :loading="loginForm.loading" type="submit">Entrar</v-btn>
              </v-col>
            </v-row>
            <!-- <v-row justify="center">
              <v-col>
                <a class="btn-forgot-password">Esqueci minha senha</a>
              </v-col>
            </v-row> -->
          </v-form>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">
          <v-col align="left" justify="center">
            <!-- <img src="@/assets/logo-paoli.png" :height="100" /> -->
            <img src="@/assets/logo-b&j.png" :height="100" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import * as jwt from "jwt-decode";

export default {
  name: "Login",

  data: () => ({
    page: {
      slogan: null,
    },
    username: "",
    password: "",
    loading: false,
    valid: true,
    show: false,
    rules: {
      email: [
        (v) => !!v || "O usuário é obrigatório",
        // (v) =>
        //   (v && v.length <= 25) ||
        //   "O usuário deve ter menos de 10 caracteres",
      ],
      password: [(v) => !!v || "A senha é obrigatória"],
    },
    loginForm: {
      validForm: true,
      showPassword: false,
      loading: false,
      user: {},
    },
  }),

  mounted() {
    if (this.$route.query.username) {
      this.loginForm.user.username = this.$route.query.username;
    }
  },

  methods: {
    empty() {
      return (
        !this.$data.loginForm.user.username ||
        !this.$data.loginForm.user.password
      );
    },
    async login() {

      if (!this.$refs.form.validate()) return;

      let data = {
        email: this.loginForm.user.username,
        password: this.loginForm.user.password,
        type: 2,
      };

      this.$data.loginForm.loading = true;

      try {
        const result = await axios.post('/api/admin/client/login', data);

        if (!result.data.status) throw new Error();

        localStorage.setItem('token', JSON.stringify(result.data.content));

        var dataUser = jwt(result.data.content.token);

        console.log('dataUser', dataUser)

        this.$root.user = dataUser.data;
        localStorage.setItem('user', JSON.stringify(dataUser));

        router.push("/home");

      } catch (error) {
        console.log('error ', error)
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
        this.$data.loginForm.user.password = "";
      } finally {
        this.$data.loginForm.loading = false;
      }
    },

    forgotPassword() {
      this.$router.replace("/solicitar-senha");
    },

  },
};
</script>

<style scoped lang="scss">
.btn-forgot-password {
  font-size: 14px;
  color: #9fa1a4;
  font-weight: 400;
}

.title-login {
  color: var(--v-black-base);
  // font-family: Montserrat;
  font-family: 'Avenir LT Std';
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px
}

.btn-login {
  text-transform: none !important;
  letter-spacing: 1px;
  font-weight: 400;
}
</style>