import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

import EventList from "@/views/event/EventList.vue";
import EventDetails from "@/views/event/EventDetails.vue";

import RequestDocumentList from "@/views/request-document/RequestDocumentList.vue";
import RequestDocumentDetails from "@/views/request-document/RequestDocumentDetails.vue";

import * as jwt from "jwt-decode";

//the route guard
const guard = function (to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  // const checked = this.$check(to.name, 'GET');


  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
  );
  if (!token && to.path !== "/login" && !publicPath) {
    next({
      path: "/login",
    });
  } else {

    console.log('to.name ', to.name)

    if (to.name == "Home") {
      next();
      return;
    }

    const resolvedToken = jwt(token);

    // const profile = adminProfiles.find((x) => x.id === resolvedToken.data.profile);

    // if(profile.permissions && profile.permissions[0] === '*') return next();

    // const checkPermission = profile.permissions.find((x) => x.path === to.name);

    // if(!checkPermission) {
    //   next({
    //     path: "/home",
    //   });
    //   return;
    // }

    // const checkMethod = checkPermission.method.includes('GET')

    // if(!checkMethod) {
    //   next({
    //     path: "/home",
    //   });
    //   return;
    // }

    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/",
        redirect: "/login",
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Private.vue"),
    beforeEnter: guard,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: guard,
      },
      {
        path: "/divergencias",
        name: "Event",
        component: EventList,
        beforeEnter: guard,
      },
      {
        path: "/divergencias/:id",
        name: "Event",
        component: EventDetails,
        beforeEnter: guard,
      },
      {
        path: "/solicitacao-documentos",
        name: "RequestDocument",
        component: RequestDocumentList,
        beforeEnter: guard,
      },
      {
        path: "/solicitacao-documentos/:id",
        name: "RequestDocument",
        component: RequestDocumentDetails,
        beforeEnter: guard,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
